<template>
  <div>
    <div class="scanner" :class="message == 'добавлено' ? 'green' : 'blueMain'">
      <Scanner @find_product="find_product" :key="key" />
      <v-card
        v-if="message && message != 'добавлено'"
        class="ma-4 pa-4 orangeDark--text text-18 text-center rounded-xl"
      >
        {{ message }}
      </v-card>
    </div>
    <div class="position-top mt-3">
      <div v-if="vuex_stock_orders[Ref_Key]" class="text-14">
        <div
          v-if="vuex_stock_orders[Ref_Key].First_shipment"
          class="redMain white--text px-3"
        >
          первая отгрузка
        </div>
        <div v-if="vuex_stock_orders[Ref_Key].Comment" class="d-flex">
          <div class="grey3--text header text-right pr-3">
            комментарий
          </div>
          <div class="redMain--text">
            {{ vuex_stock_orders[Ref_Key].Comment }}
          </div>
        </div>
        <div v-for="(v, k) in order_header" :key="k">
          <div v-if="vuex_stock_orders[Ref_Key][v]" class="d-flex">
            <div class="grey3--text header text-right pr-3">
              {{ k }}
            </div>
            <div class="blueDark--text">
              {{ vuex_stock_orders[Ref_Key][v] }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="vuex_stock_orders[Ref_Key]" class="text-14">
        <div v-for="(v, k) in doc_header" :key="k">
          <div v-if="vuex_stock_orders[Ref_Key][v]" class="d-flex">
            <div class="grey3--text header text-right pr-3">
              {{ k }}
            </div>
            <div class="orangeDark--text">
              {{ vuex_stock_orders[Ref_Key][v] }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="other_doc.Распоряжение_Type" class="text-14 d-flex">
           <div class="grey3--text header text-right pr-3">
           распоряжение
           </div>
           <div class="orangeDark--text">
           {{
           other_doc.Распоряжение_Type
           .replace("StandardODATA.", "")
           .replace("Document_", "")
           }}
           </div>
           </div> -->
      <div v-if="all_crpt_ok" class="d-flex">
        <v-btn text color="white" class="orange mt-3 mx-auto"
          >Записать в 1С</v-btn
        >
      </div>

      <div v-if="product.length > 0" class="text-12 mt-4">
        <v-card
          elevation="2"
          v-for="p in product"
          :key="p.Ref_Key"
          class="py-1 mx-1"
        >
          <div class="py-1 mx-1 d-flex">
            <div class="px-1" style="width: 60%">
              <div class="grey3--text">
                <img
                  v-if="p['ВидНоменклатуры.Description'] == 'Текстиль'"
                  :src="image"
                  width="11"
                  height="11"
                />
                {{ p["Производитель.Description"] }}
              </div>
              <div>{{ p.Description }}</div>
            </div>
            <div
              class="d-flex flex-rows"
              style="position: absolute; right: 26%"
            >
              <v-icon color="blueMain" style="opacity: 0.3"
                >mdi-package-variant</v-icon
              >
              <div class="text-24 blueMain--text">
                {{ p.КоличествоУпаковок }}
              </div>
            </div>
            <div class="ml-auto text-right">
              <div>
                <span
                  v-if="p.barcode"
                  class="rounded-lg"
                  :class="
                    p.Количество == p.barcode.length
                      ? 'white green--text text-24'
                      : 'orangeDark white--text text-18'
                  "
                >
                  <img :src="image" width="13" height="13" />
                  <span v-if="p.Количество != p.barcode.length">
                    {{ p.barcode.length }}<span class="text-10 mr-1">из</span>
                  </span>
                  <span v-else>✓</span>
                </span>
                <span class="text-24">{{ p.Количество }}</span
                ><span class="mr-2"> шт.</span>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <!-- <div class="text-9">
         {{ active_document }}
         </div> -->
  </div>
</template>

<script>
import axios from "axios";
import Scanner from "./Scanner.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Rashodnik",
  components: {
    Scanner
  },
  data: () => ({
    key: Math.random(),
    message: undefined,
    image: "https://251317.selcdn.ru/oboi.ru/BlogSrc/chestnyznak.svg",
    order_header: {
      склад: "Stock",
      "номер документа": "Number",
      тип: "Store_operation",
      статус: "Status",
      приоритет: "Priority"
    },
    doc_header: {
      заказ: "Number",
      менеджер: "Manager",
      клиент: "Client",
      адрес: "Address"
    }
  }),
  computed: {
    ...mapState([
      "back_domain",
      "active_document",
      "vuex_stock_orders",
      "vuex_sale_orders"
    ]),

    all_crpt_ok() {
      let need_crpt = false;
      for (let el of this.active_document.Products) {
        if (el["ВидНоменклатуры.Description"] == "Текстиль") {
          need_crpt = true;
          if (!el.barcode || el.Количество != el.barcode.length) return false;
        }
      }
      if (need_crpt) return true;
      return false;
    },
    Ref_Key() {
      return Object.keys(this.active_document)[0];
    },
    doc() {
      if (this.active_document.client_orders.length > 0) {
        return this.active_document.client_orders[0];
      }
      return {};
    },
    other_doc() {
      if (this.active_document.other_documents.length > 0) {
        return this.active_document.other_documents[0];
      }
      return {};
    },
    product() {
      // return [];
      if (
        this.active_document.Products &&
        this.active_document.Products.length > 0
      )
        return this.sort_product();
      else return [];
    }
  },
  methods: {
    ...mapMutations(["new_active_document"]),

    sort_product: function() {
      return this.active_document.Products.sort(function(a, b) {
        let a_val = a["LineNumber"];
        let b_val = b["LineNumber"];
        if (a_val > b_val) return 1;
        if (a_val <= b_val) return -1;
      }).sort(function(a, b) {
        let a_val = a["ВидНоменклатуры.Description"];
        let b_val = b["ВидНоменклатуры.Description"];
        if (a_val > b_val) return 1;
        if (a_val <= b_val) return -1;
      });
    },
    find_product(prod) {
      let message = this.add_barcode(prod.guid, prod.datamatrix);
      this.add_message(message);
    },

    async get_product() {
      // тащим данные из 1С
      // http://0.0.0.0:9061/
      return axios
        .post(this.back_domain + "service/order_product", {
          Ref_Key: this.active_document[this.Ref_Key].Ref_Key
        })
        .then(res => res.data)
        .then(json => {
          this.new_active_document({
            ...this.active_document,
            Products: json.response
          });
          // console.log(json.response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    add_message(message) {
      this.message = message;
      let timeout = 1500;
      if (message == "добавлено") timeout = 500;
      setTimeout(() => {
        this.key = Math.random();
        this.message = undefined;
      }, timeout);
    },
    add_barcode(ref_key, barcode) {
      console.log(`Document.vue | add_barcode(${ref_key}, ${barcode})`);
      if (!ref_key || !barcode) return;
      if (!this.active_document.Products) return "в ордере нет товаров";
      let product = this.active_document.Products.filter(
        el => el.Ref_Key == ref_key
      );
      if (!product.length) return "такого нет в ордере";
      product = product[0];
      if (product.barcode && product.barcode.includes(barcode))
        return "уже был отсканирован";
      if (
        product.Количество <= 0 ||
        (product.barcode && product.Количество - product.barcode.length <= 0)
      )
        return "превышено количество в ордере";
      product.barcode = product.barcode
        ? [...product.barcode, barcode]
        : [barcode];
      this.new_active_document({ ...this.active_document });
      return "добавлено";
    }
  },
  created: function() {
    if (!this.active_document.products) this.get_product();
  }
};
</script>

<style scoped>
.header {
  width: 42%;
}
.scanner {
  position: fixed;
  width: 100%;
  top: 20px;
  z-index: 10;
}
</style>
