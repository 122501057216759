import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login_show: false,
    camera_enabled: false,
    article_searcher_enabled: true,
    camera_accessible: true,
    active_document: {},
    vuex_sale_orders: {},
    vuex_stock_orders: {},
    vuex_placement: {},
    vuex_stock: {},
    back_domain: process.env.VUE_APP_BACK_DOMEN || ""
  },
  mutations: {
    login_on(state) {
      state.login_show = true;
    },

    login_off(state) {
      state.login_show = false;
    },

    camera_on(state) {
      state.camera_enabled = true;
      console.log("camera_on | camera_enabled =", state.camera_enabled);
    },

    camera_off(state) {
      state.camera_enabled = false;
      console.log("camera_off | camera_enabled =", state.camera_enabled);
    },

    article_searcher_on(state) {
      state.article_searcher_enabled = true;
      console.log("article_searcher_enabled = true");
    },

    article_searcher_off(state) {
      state.article_searcher_enabled = false;
      console.log("article_searcher_enabled = false");
    },

    camera_not_found(state) {
      state.camera_accessble = false;
    },

    update_vuex_sale_orders(state, obj) {
      // console.log(obj);
      state.vuex_sale_orders = Object.assign(obj, state.vuex_sale_orders);
    },

    update_vuex_stock_orders(state, obj) {
      state.vuex_stock_orders = Object.assign(obj, state.vuex_stock_orders);
    },

    update_vuex_stock(state, obj) {
      // console.log(obj);
      state.vuex_stock = Object.assign(obj, state.vuex_stock);
    },

    update_vuex_placement(state, obj) {
      // console.log(obj);
      state.vuex_placement = Object.assign(obj, state.vuex_placement);
    },

    new_active_document(state, value) {
      // console.log("vuex new_active_document()", value);
      state.active_document = value;
    }
  },
  actions: {}
});
