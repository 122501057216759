<template>
  <div class="mb-12">
    <v-img
      v-show="loading"
      height="60"
      class="ma-4 grey1--text rounded-xl"
      src="processing.gif"
    >
    </v-img>
    <ProductCard v-if="product.guid" :product="product" />

    <div v-if="button_exit_activate" class="d-flex">
      <v-btn
        text
        x-large
        class="mt-12 mx-auto px-12 py-6 text-18 text-center rounded-xl grey7--text grey3"
        @click="cancel()"
      >
        отмена
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProductCard from "./ProductCardComponent";
import { mapState } from "vuex";
export default {
  name: "DatamatrixProduct",
  components: {
    ProductCard,
  },
  props: {
    barcode_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    datamatrix: undefined,
    loading: false,
    button_exit_activate: false,
    product: {},
  }),
  computed: {
    ...mapState(["back_domain", "active_document"]),
  },
  methods: {
    cancel() {
      this.$emit("sendMessage", "cancel");
      this.button_exit_activate = false;
      this.$emit("finishd");
    },
    async get_matrix_data() {
      // получение инфы по матрикс коду
      if (this.loading) return;
      this.loading = true;
      let document = undefined;
      if (this.active_document.client_orders)
        document = this.active_document.client_orders[0].number;
      return axios
        .post(this.back_domain + "service/matrix", {
          datamatrix: this.barcode_data.barcode,
          order_number: document,
        })
        .then((res) => res.data)
        .then((json) => {
          if (!json.response.guid && !json.response.company) {
            this.$emit("sendMessage", "datamatrix_not_found");
          } else {
            this.product = json.response;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$emit("sendMessage", "server_error");
        });
    },

    async get_barcode_data() {
      // получение инфы по баркоду
      // http://0.0.0.0:9061/
      if (this.loading) return;
      this.$emit("sendMessage", undefined);
      this.loading = true;
      let document = undefined;
      if (this.active_document.client_orders)
        document = this.active_document.client_orders[0].number;
      return axios
        .post(this.back_domain + "service/barcode_matrix", {
          datamatrix: this.datamatrix,
          order_number: document,
          barcode: this.barcode_data.barcode,
        })
        .then((res) => res.data)
        .then((json) => {
          this.loading = false;
          this.product = json.response;
          this.button_exit_activate = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.button_exit_activate = true;
          this.$emit("sendMessage", "server_error");
        });
    },
  },
  watch: {
    barcode_data() {
      if (
        !this.product.guid &&
        this.barcode_data.barcode &&
        this.barcode_data.format == "barcode"
      ) {
        // отсканирован штрихкод - запрашиваю
        this.product = {};
        this.get_barcode_data();
      } else if (
        !this.product.guid &&
        this.barcode_data.format == "datamatrix"
      ) {
        // отсканирован НЕ штрихкод
        this.$emit("sendMessage", "need_barcode_not_datamatrix");
      }
    },
    product() {
      // console.log(this.product);
      if (
        Object.keys(this.product).length > 0 &&
        !this.product.guid &&
        !this.product.barcode
      ) {
        // datamatrix не найден в 1С
        // прошу отсканировать штрихкод или отменить
        this.$emit("sendMessage", "need_barcode");
        this.button_exit_activate = true;
      } else if (Object.keys(this.product).length > 0 && !this.product.guid) {
        // datamatrix не найден в 1С, штрихкод не найден
        // прошу повторить или отменить
        this.$emit("sendMessage", "barcode_not_found");
      } else if (this.product.guid) {
        // нашли товар в 1С - все успешно. финиш
        this.button_exit_activate = false;
        this.$emit("returnProduct", this.product);
        this.$emit("sendMessage", undefined);
        this.$emit("finishd");
      }
    },
  },
  created: function () {
    console.log("DatamatrixProduct created", this.barcode_data.barcode);
    // запоминаю datamatrix
    this.datamatrix = this.barcode_data.barcode;
    // забираю данные - датаматрикс из честного знака не доступн. выпиливаю
    // this.get_matrix_data();
  },
};
</script>

<style scoped></style>
