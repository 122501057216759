<template>
  <v-card class="ma-4 pa-4 grey1--text rounded-xl" v-if="product.guid">
    <div class="text-16 blueMain--text">
      {{ product.name }}
    </div>
    <div class="text-24">
      {{ product.article }}
    </div>
    <div class="text-16 mt-n1">
      {{ product.vendor }}
    </div>

    <div class="d-flex flex-wrap mt-3">
      <v-card
        v-for="el in product.storage"
        :key="el.name"
        flat
        height="20"
        dark
        :color="el.basic ? 'blueMain' : 'grey3'"
        class="text-18 rounded-lg px-3 mr-2"
      >
        <div class="mt-n1">
          {{ el.name }}
        </div>
      </v-card>
    </div>

    <div class="mt-3">
      <v-row v-if="product.barcode">
        <v-col cols="1">
          <v-icon color="grey" class="mr-1 mt-n2">mdi-barcode</v-icon>
        </v-col>
        <v-col class="text-12 grey--text text-left">
          <div>{{ product.barcode }}</div>
        </v-col>
      </v-row>
    </div>

    <div v-if="product.datamatrix">
      <div class="d-flex flex-row justify-center white px-3 mt-5 mb-1">
        <v-divider class="lime mr-1"> </v-divider>
        <img width="12" height="12" :src="image_crpt" class="mt-n1" />
        <v-divider class="lime ml-1"> </v-divider>
      </div>
      <div class="justify-center text-11 d-flex flex-row">
        <div>{{ product.datamatrix }}</div>
        <div class="ml-1 redMain--text">{{ product.company }}</div>
      </div>
    </div>

    <div class="text-9 grey3--text mt-3">
      <!-- {{ product }} -->
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ProductCard",
  props: { product: Object },
  data: () => ({
    image_crpt: "https://251317.selcdn.ru/oboi.ru/BlogSrc/chestnyznak.svg",
    image_barcode:
      "https://251317.selcdn.ru/oboi.ru/BlogSrc/%D0%BE%D0%B1%D0%BE%D0%B8.jpeg"
  })
};
</script>

<style scoped></style>
