<template>
  <div>
    <v-card
      v-show="isLoading"
      class="my-4 mx-auto rounded-xl scanner-container"
      max-height="200"
      max-width="400"
      :class="greenBackground ? 'scanner-find' : ''"
    >
      <video
        class="grey1--text rounded-xl"
        poster1="data:image/gif,AAAA"
        ref="scanner"
      ></video>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  BrowserMultiFormatReader,
  DecodeHintType,
  BarcodeFormat,
  Exception
} from "@zxing/library";

export default {
  name: "ScannerCamera",

  props: {
    debug: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      greenBackground: false, //код отсканирован
      input: undefined,
      format: undefined, //datamatrix or barcode
      isLoading: false,
      codeReader: undefined,
      result: {}, //scanner result
      isMediaStreamAPISupported:
        navigator &&
        navigator.mediaDevices &&
        "enumerateDevices" in navigator.mediaDevices
    };
  },
  computed: {
    ...mapState(["camera_enabled", "camera_accessible"]),
    ...mapMutations(["camera_not_found"])
  },
  watch: {
    camera_enabled() {
      if (this.camera_enabled) {
        this.start();
      } else {
        this.stop();
      }
    }
  },
  methods: {
    green_background() {
      // включает зеленую тень на 1 секунду
      this.greenBackground = true;
      setTimeout(() => {
        this.greenBackground = false;
      }, 1000);
    },
    stop() {
      if (this.codeReader) this.codeReader.reset();
      this.isLoading = false;
    },
    start() {
      if (!this.isMediaStreamAPISupported) {
        throw new Exception("Media Stream API is not supported");
      } else {
        this.codeReader = new BrowserMultiFormatReader(
          new Map().set(DecodeHintType.POSSIBLE_FORMATS, [
            BarcodeFormat.EAN_13,
            BarcodeFormat.DATA_MATRIX
          ])
        );
        this.isLoading = true;
        this.codeReader.decodeFromVideoDevice(
          undefined,
          this.$refs.scanner,
          result => {
            if (result) {
              this.green_background();
              this.result = { ...result };
              if (this.debug) console.log("result>", result);
              let format = undefined;
              let input = undefined;
              if (result.format == 5) {
                let datamatrix = result.text
                  .split("\u001d")
                  .join("")
                  .match(/01.{14}21.{13}(?=91)/);
                format = "datamatrix";
                input = datamatrix[0];
              } else {
                input = result.text;
                format = "barcode";
              }
              if (input && input != this.input) {
                // нашли новый код
                this.$emit("codeDetected");
                this.format = format;
                this.input = input;
                // задержка, избегаю конфликта с this.$emit("codeDetected");
                setTimeout(() => {
                  this.$emit("putData", {
                    barcode: this.input,
                    format: this.format
                  });
                }, 50);
              }
            }
          }
        );
      }
    }
  },
  mounted() {},

  created() {
    if (this.camera_enabled) this.start();
  },

  beforeDestroy() {
    if (this.codeReader) this.codeReader.reset();
  }
};
</script>

<style scoped>
.camera_button {
  position: absolute;
  right: 20px;
  top: 3px;
}
.camera_button_off {
  position: absolute;
  right: 20px;
  top: 3px;
  z-index: 100;
}
video {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.scanner-container {
  position: absolut;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanner-find {
  box-shadow: 0px 0px 20px green !important;
}
</style>
