<template>
  <v-card flat class="pa-1 grey1--text transparent" v-if="document">
    <v-card
      v-for="(doc, key) in sorted_document()"
      :key="key"
      class="rounded-md d-flex text-12"
      style="margin-top: 1px"
      @click.stop="new_active_document({ [key]: doc })"
      elevation="1"
    >
      <div style="width: 56px;" class="px-1 d-flex flex-column stock ">
        <div v-if="doc.Product_count" class="d-flex flex-rows">
          <v-icon small color="" style="opacity: 0.3" class="mr-1"
            >mdi-format-list-numbered
          </v-icon>
          <div class="text-h6 text-right grey3--text" style="width: 100%">
            {{ doc.Product_count }}
          </div>
        </div>
      </div>
      <div style="width: 100%" v-if="doc">
        <v-row
          class="ma-0"
          v-if="doc.First_shipment || doc.Priority != 'Средний'"
        >
          <v-col class="py-0 pr-0">
            <div
              v-if="doc.First_shipment"
              class="orangeDark text-12 white--text pl-2"
            >
              ПЕРВАЯ ОТГРУЗКА
            </div>
            <div
              v-if="doc.Priority != 'Средний'"
              class="blueMain text-12 white--text pl-2"
            >
              приоритет
              {{ doc.Priority }}
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="8" class="py-0">
            <div class="d-flex flex-column" v-if="vuex_sale_orders[key]">
              <div class="d-flex justify-space-between">
                <div class="text-18">
                  <span> {{ vuex_sale_orders[key].Number }}</span>
                </div>
              </div>
              <div class="text-left grey3--text mt-n1">
                {{ vuex_sale_orders[key].Manager }}
              </div>
            </div>
            <div class="d-flex flex-column" v-else>
              <div
                class="text-14"
                v-for="other in doc.Orders"
                :key="other.Ref_Key"
              >
                {{
                  other.Document_type.replace("StandardODATA.", "").replace(
                    "Document_",
                    ""
                  )
                }}
              </div>
            </div>
          </v-col>
          <v-col class="py-0">
            <div class="d-flex flex-column align-end">
              <span class="grey1--text">
                {{ doc.Number }}
              </span>
              <span
                :class="
                  doc.Status == 'Отгружен' ? 'grey--text' : 'orangeDark--text '
                "
              >
                {{ doc.Status }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0" v-if="vuex_sale_orders[key]">
          <v-col class="py-0 pr-0 mt-n1">
            <div class="text-left text-14">
              {{ vuex_sale_orders[key].Client }}
            </div>
            <div class="orangeDark--text text-10">
              {{ doc.Comment }}
            </div>
            <div
              class="text-left text-9 grey3--text"
              v-if="vuex_sale_orders[key]"
            >
              {{ vuex_sale_orders[key].Delivery_type }}
            </div>
            <div class="grey4--text text-10" v-if="vuex_sale_orders[key]">
              {{ vuex_sale_orders[key].Address }}
            </div>
            <div></div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Stock",
  components: {},
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    Ref_Keys: []
  }),

  computed: {
    ...mapState(["vuex_sale_orders"])
  },
  methods: {
    ...mapMutations(["new_active_document"]),
    sorted_document: function() {
      return Object.entries(this.document)
        .sort((a, b) => {
          let a_val = a[1].Number;
          let b_val = b[1].Number;
          if (a_val > b_val) return -1;
          if (a_val <= b_val) return 1;
        })
        .sort((a, b) => {
          if (this.vuex_sale_orders[a[0]] && this.vuex_sale_orders[b[0]]) {
            let a_val = this.vuex_sale_orders[a[0]].Client;
            let b_val = this.vuex_sale_orders[b[0]].Client;
            if (a_val > b_val) return 1;
            if (a_val <= b_val) return -1;
          } else return 0;
        })
        .reduce((acc, el) => (el ? { ...acc, [el[0]]: el[1] } : acc), {});
    }
  },

  created: function() {}
};
</script>

<style scoped>
.stock {
  border-style: groove;
  border-width: 1px;
  border-color: transparent transparent transparent transparent;
}
</style>
