import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        redMain: "#F21A3F",
        redLight: "#FF7477",
        blueLight: "#F1FAEE",
        blueMain: "#457B9D",
        blueDark: "#1D3557",
        orangeDark: "F64F21",
        grey1: "#333333",
        grey2: "#4F4F4F",
        grey3: "#828282",
        grey4: "#BDBDBD",
        grey5: "#E0E0E0",
        grey6: "#F2F2F2",
        grey7: "#FBFBFB"
      }
    }
  }
});
