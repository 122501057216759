<template>
  <div v-if="article_searcher_enabled">
    <div v-if="product" style="padding-bottom: 100%">
      <div v-for="prod in product" :key="prod.Ref_Key">
        <Product :product="prod" />
      </div>
    </div>
    <v-card flat class="keyboard d-flex rounded-xl">
      <div class=" d-flex flex-wrap justify-end">
        <div
          v-if="input"
          style="width: 100%"
          :style="
            $vuetify.breakpoint.width >= 380
              ? 'max-width: 284px;'
              : 'max-width: 240px;'
          "
          class="mb-3 blueMain rounded-xl pa-1"
        >
          <div class="d-flex">
            <div class="mr-auto">
              <v-btn
                small
                fab
                @click="
                  input = '';
                  product = [];
                "
                class="blueMain--text"
                elevation="0"
                >x</v-btn
              >
            </div>
            <div class="text-right text-38 white--text">
              <h3>{{ input }}</h3>
            </div>
          </div>
        </div>
        <v-btn
          large
          :x-large="$vuetify.breakpoint.width >= 380 ? true : false"
          elevation="0"
          class="btn mb-1 ml-1 rounded-xl"
          v-for="k in [7, 8, 9, 4, 5, 6, 1, 2, 3, 0, '-', '%']"
          :key="k"
          @click="input += k"
          ><span class="text-38">{{ k }}</span></v-btn
        >
      </div>
      <div>
        <v-btn
          style="height: 100%"
          elevation="0"
          :disabled="!input"
          large
          class="mb-1 ml-1 orangeDark pa-0 rounded-xl"
          @click="
            get_product();
            input = '';
          "
        >
          <v-icon x-large class="white--text ma-0">
            mdi-magnify
          </v-icon>

          <!-- <span class="text-38 white--text">O</span> -->
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import Product from "./ProductComponent";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "ArticleSearcher",
  components: {
    Product
  },
  props: {
    debug: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      input: "",
      product: []
    };
  },
  computed: {
    ...mapState(["back_domain", "article_searcher_enabled"])
  },
  watch: {
    input() {
      if (this.input.length === 1) this.product = [];
    }
  },
  methods: {
    key_click(key) {
      console.log(this.input, "+", key);
      this.input = this.input + key;
      console.log(this.input);
    },

    async get_product() {
      return axios
        .post(this.back_domain + "service/product_via_text", {
          filter: this.input
        })
        .then(res => res.data)
        .then(json => {
          if (json.response) this.product = json.response;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {},

  created() {
    if (this.camera_enabled) this.start();
  },

  beforeDestroy() {}
};
</script>

<style scoped>
.keyboard {
  position: fixed;
  bottom: 100px;
  right: 5px;
  max-width: 400px;
  z-index: 100;
}
.btn {
}
</style>
