<template>
  <div class="">
    <v-img
      v-show="loading"
      class="ma-4 grey1--text rounded-xl"
      src="processing.gif"
    >
    </v-img>

    <v-expansion-panels accordion focusable>
      <v-expansion-panel v-for="st_name in stock_names" :key="st_name">
        <v-expansion-panel-header class="text-h5">
          <div
            style="text-transform: capitalize; height: 60px"
            class="text-left grey2--text pt-3"
          >
            <v-icon large class="mr-4" style="opacity: 0.2; margin-top: -8px">
              mdi-warehouse
            </v-icon>
            {{ st_name.toLowerCase().replace("склад", "") }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Stock :document="stock_order_in_stock(st_name)" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels accordion focusable class="mt-1">
      <v-expansion-panel v-for="dt in delivery_task" :key="dt.Ref_Key">
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon
              large
              color="grey3"
              style="opacity: 0.4;margin-bottom: -8px"
              class="mr-2"
            >
              mdi-truck-outline
            </v-icon>
            <div
              v-if="dt.Auto_number.length >= 8"
              class="text-16 grey1--text grey6 rounded-lg py-2 px-4"
            >
              {{ (aut_number = dt.Auto_number.split(""))[0] }}
              <span class="text-24">
                {{ aut_number.slice(1, 4).join("") }}</span
              >
              <span> {{ aut_number.slice(4, 6).join("") }}</span>
              <span class="grey5--text"> | </span>
              <span>{{ aut_number.slice(6).join("") }} </span>
            </div>
            <div v-else class="text-16 grey6 rounded-lg py-2 px-4">
              {{ dt.Auto_number }}
            </div>
          </div>
          <div class="text-right d-flex flex-column" style="margin-top: -10px">
            <div
              style="opacity: 0.7"
              class="font-weight-thin grey2--text"
              v-if="dt.Stock"
            >
              <v-icon small class="mb-1" style="opacity: 0.4">
                mdi-warehouse
              </v-icon>
              {{
                dt.Stock.toLowerCase()
                  .replace("склады", "")
                  .replace("склад", "")
              }}
            </div>
            <div
              class="grey2--text"
              style="text-transform: capitalize;"
              v-if="dt.Auto_model"
            >
              {{ dt.Auto_model.toLowerCase() }}
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Automobile :task="dt" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import Automobile from "./DocumentAutomobileComponent";
import Stock from "./DocumentStockComponent";
export default {
  name: "Rashodnik",
  components: { Automobile, Stock },
  data: () => ({
    loading_delivery_task: false,
    loading_stock_order: false,
    delivery_task: [],
    sale_order_key_in_car: []
  }),
  computed: {
    ...mapState(["vuex_stock_orders", "vuex_sale_orders", "back_domain"]),
    loading() {
      if (this.loading_delivery_task || this.loading_order) return true;
      return false;
    },
    stock_order_nin_car() {
      if (!Object.keys(this.vuex_stock_orders).length) return [];
      return Object.entries(this.vuex_stock_orders).reduce(
        (acc, el) =>
          !this.sale_order_key_in_car.includes(el[0])
            ? { ...acc, [el[0]]: el[1] }
            : acc,
        {}
      );
    },

    stock_names() {
      return [
        ...new Set(
          Object.values(this.stock_order_nin_car)
            .filter(el => el.Stock)
            .map(el => el.Stock)
        )
      ];
    }
  },
  methods: {
    ...mapMutations(["update_vuex_stock_orders", "update_vuex_sale_orders"]),

    stock_order_in_delivery_task(delivery_task) {
      let document = [];
      for (let Ref_Key of delivery_task.Orders_key) {
        console.log(this.vuex_stock_orders[Ref_Key]);
        document = document.concat(this.vuex_stock_orders[Ref_Key]);
      }
      return document;
    },

    stock_order_in_stock(stock_name) {
      return Object.entries(this.stock_order_nin_car).reduce(
        (acc, el) =>
          el[1].Stock == stock_name ? { ...acc, [el[0]]: el[1] } : acc,
        {}
      );
    },

    sale_order_all_keys() {
      return Object.keys(this.vuex_stock_orders);
    },
    async get_sale_orders() {
      return axios
        .post(this.back_domain + "service/sale_order", {
          filter: this.sale_order_all_keys()
        })
        .then(res => res.data)
        .then(json => {
          if (json.response) this.update_vuex_sale_orders(json.response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    async get_stock_orders() {
      this.loading_stock_order = true;
      return axios
        .post(this.back_domain + "service/stock_order", {})
        .then(res => res.data)
        .then(json => {
          if (json.response) this.update_vuex_stock_orders(json.response);
          this.loading_stock_order = false;
          // загрузим справочник заказов клиентов в vuex
          this.get_sale_orders();
        })
        .catch(error => {
          console.log(error);
          this.loading_stock_order = false;
        });
    },
    async get_delivery_task() {
      this.loading_deliverty_task = true;
      return axios
        .post(this.back_domain + "service/delivery_task", {})
        .then(res => res.data)
        .then(json => {
          this.delivery_task = json.response.map(el => {
            return { ...el, Orders_key: el.Orders.map(el => el.Ref_Key) };
          });
          for (let el of this.delivery_task) {
            this.sale_order_key_in_car = this.sale_order_key_in_car.concat(
              el.Orders_key
            );
          }
          this.loading_deliverty_task = false;
        })
        .catch(error => {
          console.log(error);
          this.loading_deliverty_task = false;
        });
    }
  },
  created: function() {
    this.get_stock_orders();
    this.get_delivery_task();
  }
};
</script>

<style scoped>
.not_padding {
  $expansion-panel-content-padding: 0 2px 6px !important;
}
</style>
