<template>
  <v-card class="ma-1 pa-3 grey1--text rounded-xl" v-if="product">
    <div class="d-flex">
      <div class="text-24 mr-3">
        {{ product.Article }}
      </div>
      <div class="d-flex flex-wrap mt-2">
        <div v-for="pl in placement" :key="'3' + pl.Place_Key">
          <div v-if="vuex_placement[pl.Place_Key]">
            <v-card
              flat
              height="15"
              style="opacity: 0.6"
              :color="pl.MainPlace ? 'blueMain lighten-3' : 'grey3 lighten-3'"
              class="text-14 rounded-lg px-2 mr-1"
            >
              <div style="position: relative; bottom: 2px">
                {{ vuex_placement[pl.Place_Key].Name }}
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="text-16">
      {{ product.Name }}
      <span class="grey4--text text-18"> | </span>
      {{ product.Vendor }}
    </div>
    <v-divider class="mb-3 mt-1"> </v-divider>
    <v-row
      class="px-6 my-1 mt-1"
      v-for="balance in leftovers"
      :key="balance.Series_Key + balance.Stock_Key"
    >
      <v-col cols="4" class="pa-0">
        <div class=" text-16 d-flex" style="opacity: 0.7; margin-top: 1px">
          <v-icon small color="" style="opacity: 0.4">
            mdi-warehouse
          </v-icon>
          <div class="ml-1" style="">
            {{
              vuex_stock[balance.Stock_Key].Name.toLowerCase().replace(
                "склад",
                ""
              )
            }}
          </div>
        </div>
      </v-col>
      <v-col cols="3" class="pa-0">
        <div class="text-18 font-weight-medium">
          <span
            class="orangeDark rounded-lg px-2 white--text"
            style="position: relative; bottom: -1px"
          >
            {{ balance.Balance }}
          </span>
        </div>
      </v-col>
      <v-col class="pa-0 d-flex flex-wrap mt-1 ml-n3">
        <div v-for="pl in placement" :key="'2' + pl.Place_Key">
          <v-card
            v-if="
              pl.Stock_Key == balance.Stock_Key && vuex_placement[pl.Place_Key]
            "
            flat
            height="20"
            dark
            :color="pl.MainPlace ? 'blueMain lighten-2' : 'grey3 lighten-2'"
            class="text-16 rounded-lg px-2 mr-1"
          >
            <div style="position: relative; bottom: 2px">
              {{ vuex_placement[pl.Place_Key].Name }}
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <!-- <div class="d-flex flex-column flex-wrap mt-1">
         <v-card
         flat
         v-for="balance in leftovers"
         :key="balance.Series_Key + balance.Stock_Key"
         class="px-1 mb-1"
         >
         <div class="d-flex">
         <div class="orangeDark--text" style="opacity: 0.7">
         <v-icon
         small
         color="orangeDark"
         style="opacity: 0.4;margin-top: -6px"
         >
         mdi-warehouse
         </v-icon>
         {{
         vuex_stock[balance.Stock_Key].Name.toLowerCase().replace(
         "склад",
         ""
         )
         }}
         </div>
         <h3>
         {{ balance.Balance }}
         </h3>
         </div>
         <div class="d-flex flex-wrap">
         <div>
         <h3 class="mt-n1">
         {{ balance.Balance }}
         </h3>
         </div>
         <div v-for="pl in placement" :key="'2' + pl.Place_Key">
         <v-card
         v-if="
         pl.Stock_Key == balance.Stock_Key &&
         vuex_placement[pl.Place_Key]
         "
         flat
         height="20"
         dark
         :elevation="pl.MainPlace ? '2' : '0'"
         :color="pl.MainPlace ? 'blueMain' : 'grey3'"
         class="text-18 rounded-lg px-3 ml-2"
         >
         <div style="position: relative; bottom: 3px">
         {{ vuex_placement[pl.Place_Key].Name }}
         </div>
         </v-card>
         </div>
         </div>
         </v-card>
         </div> -->
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "ProductCard",
  props: { product: Object },
  data: () => ({
    placement: [],
    leftovers: [],
    image_crpt: "https://251317.selcdn.ru/oboi.ru/BlogSrc/chestnyznak.svg",
    image_barcode:
      "https://251317.selcdn.ru/oboi.ru/BlogSrc/%D0%BE%D0%B1%D0%BE%D0%B8.jpeg"
  }),
  methods: {
    click(key) {
      this.input = this.input + key;
    },
    async get_leftovers() {
      return axios
        .post(this.back_domain + "service/leftovers_product", {
          filter: this.product.Ref_Key
        })
        .then(res => res.data)
        .then(json => {
          if (json.response) this.leftovers = json.response;
        })
        .catch(error => {
          console.log(error);
        });
    },

    async get_placement() {
      return axios
        .post(this.back_domain + "service/placement_product", {
          filter: this.product.Ref_Key
        })
        .then(res => res.data)
        .then(json => {
          if (json.response) this.placement = json.response;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    ...mapState(["back_domain", "vuex_stock", "vuex_placement"])
  },
  created() {
    this.get_placement();
    this.get_leftovers();
  }
};
</script>

<style scoped></style>
