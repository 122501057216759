<template>
  <div v-if="!login_show">
    <div v-show="input" class="text-10 InputBarcode blueMain white--text">
      ⌨ {{ input }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ScannerKeyboard",
  props: {
    debug: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data: () => ({
    input: "",
    format: null //datamatrix or barcode
  }),
  methods: {
    onKeyDown(e) {
      if (e.keyCode == 13) {
        if (this.input) {
          this.readBarcode(this.input);
          this.returnBarcode();
          this.clearInput();
        }
        this.input = "";
        this.inputType = "";
      } else if (
        // !~["{", "}"].indexOf(e.key) &&
        e.keyCode != 16 &&
        e.keyCode != 119
      )
        this.input = this.input + e.key;
    },

    readBarcode(input) {
      let datamatrix = input.match(/01.{14}21.{13}(?=91)/);
      if (datamatrix) {
        this.format = "datamatrix";
        this.input = datamatrix.toString();
      } else {
        this.format = "barcode";
      }
    },

    clearInput() {
      this.input = null;
      this.format = null;
    },

    returnBarcode() {
      let data = { barcode: this.input, format: this.format };
      if (this.debug) console.log(data);
      if (!this.login_show) this.$emit("putData", data);
    }
  },

  computed: {
    ...mapState(["login_show"])
  },
  watch: {
    login_show() {
      this.input = "";
    },
    input() {
      if (this.input.length == 1) {
        this.$emit("startTyping");
      }
    }
  },

  created: function() {
    document.addEventListener("keydown", this.onKeyDown);
  },

  beforeDestroy: function() {
    document.removeEventListener("keydown", this.onKeyDown);
  }
};
</script>

<style scoped>
.InputBarcode:hover {
  position: absolute;
  max-height: 10px;
  top: 0px;
  padding-left: 3px;
  z-index: 11;
}
</style>
