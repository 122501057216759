<template>
  <div>
    <v-row class="white black--text">
      <v-col cols="3" class="pb-0 d-flex flex-column justify-end">
        <div>
          <v-img src="truck.png"> </v-img>
        </div>
      </v-col>
      <v-col class="d-flex flex-column pb-0">
        <v-row>
          <v-col
            cols="4"
            v-if="task.Weight"
            class="d-flex flex-column align-end px-0"
          >
            <div>
              <span class="grey--text">вес</span>
              <span class="orangeDark--text">
                {{ task.Weight.toFixed(1) }}
              </span>
            </div>
            <div>
              <span class="grey--text">объем</span> {{ task.Volume.toFixed(1) }}
            </div>
          </v-col>
          <v-col
            cols="2"
            v-if="task.Auto_volume || task.Auto_weight"
            class="d-flex flex-column align-end px-0 grey--text"
          >
            <div>➜</div>
            <div>➜</div>
          </v-col>
          <v-col
            cols="4"
            v-if="task.Auto_volume || task.Auto_weight"
            class="d-flex flex-column align-end px-0"
          >
            <div>
              {{ task.Auto_weight }} <span class="grey3--text">тон</span>
            </div>
            <div>
              {{ task.Auto_volume }} <span class="grey3--text">куб</span>
            </div>
          </v-col>
        </v-row>
        <div class="blueMain--text text-12 text-left" v-if="task.Auto_fio">
          <span class="grey3--text">водитель </span> {{ task.Auto_fio }}
        </div>
        <div class="blueMain--text text-12 text-left">
          <span class="grey3--text">планируется на </span>
          {{ new Date(Date.parse(task.Date)).toLocaleDateString("ru-RU") }}
        </div>
        <div class="orangeDark--text text-12 text-left" v-if="task.Comment">
          <span class="grey3--text">комментарий </span> {{ task.Comment }}
        </div>
      </v-col>
    </v-row>
    <v-row
      v-for="(doc, index) in task.Orders.slice().reverse()"
      :key="doc.Ref_Key"
      @click.stop="
        new_active_document({ [doc.Ref_Key]: vuex_stock_orders[doc.Ref_Key] })
      "
    >
      <v-col cols="3" class="px-3 py-0">
        <div class="text-right truck pr-1 d-flex flex-column justify-center">
          <div class="text-22 text-center">
            <span class="text-14" style="opacity: 0.3">№ </span>
            <span> {{ index + 1 }}</span>
          </div>
          <div class="text-center">
            <v-icon x-small color="orangeDark" style="opacity: 0.3"
              >mdi-weight</v-icon
            >
            <span class="orangeDark--text">
              {{ (doc.Weight / 1000).toFixed(1) }}
            </span>
          </div>
          <div class="text-center">
            <v-icon x-small color="grey3" style="opacity: 0.3">mdi-cube</v-icon>
            {{ doc.Volume.toFixed(1) }}
          </div>
        </div>
      </v-col>
      <v-col class="pa-0 pt-1" v-if="vuex_stock_orders[doc.Ref_Key]">
        <v-divider class="mt-n1 ml-n7"></v-divider>
        <v-row class="text-11">
          <v-col cols="7" class="pb-0">
            <div class="d-flex flex-column">
              <!-- <div class="text-left">
                   {{ vuex_stock_orders[doc.Ref_Key] }}
                   {{ vuex_sale_orders[doc.Ref_Key] }}
                   </div> -->
              <div class="d-flex justify-space-between text-18">
                <div v-if="vuex_sale_orders[doc.Ref_Key]">
                  <div class="blueMain--text mt-1">
                    {{ vuex_sale_orders[doc.Ref_Key].Number }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4" class="pb-0">
            <div class="d-flex flex-column align-end">
              <div class="grey1--text">
                {{ vuex_stock_orders[doc.Ref_Key].Number }}
              </div>
              <div
                class="blueMain--text mt-n1"
                v-if="vuex_sale_orders[doc.Ref_Key]"
              >
                {{ vuex_sale_orders[doc.Ref_Key].Manager.split(" ")[0] }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <div v-if="vuex_sale_orders[doc.Ref_Key]" class="text-14">
              {{ vuex_sale_orders[doc.Ref_Key].Client }}
            </div>
            <div class="redMain--text text-12">
              {{ vuex_stock_orders[doc.Ref_Key].Comment }}
            </div>
            <div
              class="grey3--text text-10"
              v-if="vuex_sale_orders[doc.Ref_Key]"
            >
              {{ vuex_sale_orders[doc.Ref_Key].Address }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="text-24 green--text d-flex align-center justify-center"
        v-else
      >
        отгружен
      </v-col>
    </v-row>
    <!-- <div class="my-10">
         {{ task }}
         </div> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Automobile",
  components: {},
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    ...mapState(["vuex_stock_orders", "vuex_sale_orders"])
  },

  methods: {
    ...mapMutations(["new_active_document"])
  },
  created: function() {}
};
</script>

<style scoped>
.truck {
  border-left: thick double #fff;
  border-right: thick double #fff;
  border-top: solid #bdbdbd;
  background-color: #f2f2f2;
  height: 100%;
}
</style>
