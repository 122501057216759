<template>
  <div>
    <!-- scanners -->
    <ScannerKeyboard
      @putData="getBarcode"
      @startTyping="startScanning"
      :debug="debug"
    />
    <ScannerCamera
      @putData="getBarcode"
      @codeDetected="startScanning"
      :debug="debug"
    />

    <!-- messager error, warning, ok -->
    <Message v-if="message" class="redMain--text" :message="message" />

    <!-- для честного знака -->
    <DatamatrixProduct
      v-if="active_component == 'DatamatrixProduct'"
      key="datamatrix"
      :barcode_data="barcode_data"
      @sendMessage="setMessage"
      @returnProduct="returnProduct"
      @finishd="setFinishd"
    />

    <!-- для штрихкода -->
    <BarcodeProduct
      v-if="active_component == 'BarcodeProduct'"
      key="barcode"
      :barcode_data="barcode_data"
      @sendMessage="setMessage"
      @returnProduct="returnProduct"
      @finishd="setFinishd"
    />
    <ArticleSearcher :debug="debug" />

    <!-- debugging -->
    <div
      class="px-6 mt-12 text-12 grey2--text"
      v-if="debug || message == 'server_error'"
    >
      <div class="py-1">
        {{ `barcode_data -- ${JSON.stringify(barcode_data)}` }}
      </div>
      <div class="py-1">{{ `active_component -- ${active_component}` }}</div>
      <div class="py-1">{{ `component_finishd -- ${component_finishd}` }}</div>
      <div class="py-1">{{ `message -- ${message}` }}</div>
      <div class="py-1">{{ `product -- ${JSON.stringify(product)}` }}</div>
    </div>
  </div>
</template>

<script>
import ScannerKeyboard from "./ScannerKeyboardComponent";
import ScannerCamera from "./ScannerCameraComponent";
import ArticleSearcher from "./ArticleSearcherComponent";
import Message from "./MessageComponent";
import DatamatrixProduct from "./DatamatrixProductComponent";
import BarcodeProduct from "./BarcodeProductComponent";

export default {
  name: "Scanner",
  components: {
    ScannerCamera,
    ScannerKeyboard,
    ArticleSearcher,
    DatamatrixProduct,
    BarcodeProduct,
    Message
  },
  data: () => ({
    product: {}, // для эмита
    barcode_data: {}, // {barcode: '01222123', format: 'datamatrix' || 'barcode'}
    active_component: undefined, // DatamatrixProduct || BarcodeProduct
    component_finishd: false, // компонент не ожидает действий и будет закрыт
    message: undefined, // отображаемое сообщение.

    user: "user",
    document: "document",

    debug: false
  }),
  watch: {
    product: function(newVal, oldVal) {
      if (newVal != oldVal) this.$emit("find_product", this.product);
    },

    message: function(newVal, oldVal) {
      if (this.debug)
        console.log("-new message           |", oldVal, "->", newVal);
    },
    barcode_data: function(newVal, oldVal) {
      if (this.debug)
        console.log(
          "-new barcode_data      |",
          JSON.stringify(oldVal),
          "->",
          JSON.stringify(newVal)
        );
    },
    active_component: function(newVal, oldVal) {
      if (this.debug)
        console.log("-new active_component  |", oldVal, "->", newVal);
    },
    component_finishd: function(newVal, oldVal) {
      if (this.debug)
        console.log("-new component_finishd |", oldVal, "->", newVal);
    }
  },
  methods: {
    returnProduct(data) {
      // пробросим данные о продукте
      this.product = data;
      this.$emit("returnProduct", this.product);
    },
    getBarcode(data) {
      // получили данные о сканировании
      this.barcode_data = data;
      this.activateComponent();
    },
    startScanning() {
      // дропнем данные предыдущего сканировани
      this.barcode_data = {};
      // дропнем сообщения
      this.message = undefined;
      // если компонент не ожидает действий - дропнем компонент
      if (this.component_finishd) {
        this.active_component = undefined;
        this.component_finishd = false;
      }
    },
    setMessage(message) {
      // установим новое сообщение
      this.message = message;
    },
    setFinishd() {
      // компонент готов к закрытию
      this.component_finishd = true;
    },
    activateComponent() {
      if (!this.active_component) {
        // активного компонента не обнаружено, запускаем
        if (this.barcode_data.format == "datamatrix") {
          this.active_component = "DatamatrixProduct";
        } else if (this.barcode_data.format == "barcode") {
          this.active_component = "BarcodeProduct";
        } else
          console.log(
            "не знаю какой компонент активировать для формата ",
            this.barcode_data.format
          );
      } else if (this.debug)
        console.log(this.active_component, "уже активирован, пропускаю");
    }
  }
};
</script>

<style scoped></style>
