<template>
  <v-card
    class="ma-4 pa-4 grey7--text text-18 text-center rounded-xl"
    :class="colors[messages[message].beep]"
  >
    {{ messages[message].text }}
  </v-card>
</template>

<script>
// import { mapState } from "vuex";
export default {
  name: "MessageComponent",
  props: {
    message: {
      type: String,
      required: true
    }
  },

  data: () => ({
    beep: {
      ok: 30,
      warning: [100, 30, 100],
      error: [200, 30, 200, 30, 200]
    },
    sound: {
      ok: "",
      warning: "",
      error: ""
    },
    colors: {
      ok: "green",
      warning: "orange",
      error: "redMain"
    },
    messages: {
      cancel: {
        text: "отменено",
        beep: "ok"
      },
      server_error: {
        text:
          "Если интернет есть но ты видишь эту ошибку, отправь скриншот в IT отдел",
        beep: "error"
      },
      datamatrix_not_found: {
        text: "Товар не найден в честном знаке",
        beep: "error"
      },
      barcode_not_found: {
        text: "этот штрихкод не найден",
        beep: "warning"
      },
      need_barcode_not_datamatrix: {
        text: "нужен ШТРИХКОД! а не честный знак",
        beep: "error"
      },
      need_barcode: {
        text: "нужен штрихкод, отсканируй",
        beep: "ok"
      }
    }
  }),
  // computed: {
  //   ...mapState(["messages", "beep"])
  // },
  watch: {
    message() {
      if (this.message) {
        //         navigator.vibrate(this.beep[this.message.beep]);
        console.log("beep", this.message.text, this.beep[this.message.beep]);
      }
    }
  }
};
</script>

<style scoped>
.position {
  position: absolute;
  top: 40px;
}
</style>
