<template>
  <v-app id="main">
    <v-system-bar app color="blueMain" dark lights-out>
      <span @click="logout()">{{ username }}</span>
      <v-spacer> </v-spacer>
      <v-icon
        class="ml-3"
        @click="article_searcher()"
        color="white"
        v-text="article_searcher_enabled ? 'mdi-keyboard' : 'mdi-keyboard-off'"
        >mdi-keyboard-off</v-icon
      >
      <v-icon
        class="ml-3"
        v-if="camera_accessible"
        @click="camera()"
        :color="camera_enabled ? 'redLight' : 'white'"
        v-text="camera_enabled ? 'mdi-camera' : 'mdi-camera-off'"
        >mdi-camera-off</v-icon
      >
    </v-system-bar>
    <Login
      @finish="close_login_dialog"
      :login_dialog="login_show"
      :email_enabled="false"
      color_background="white"
      color_main="blueMain"
      color_text_main="white"
      color_second="white"
      color_text_second="blueMain"
      button_height="50"
    />
    <v-main class="white mb-12 pb-8">
      <v-card flat color="transparent">
        <component v-bind:is="section" :key="key"></component>
      </v-card>
    </v-main>
    <v-footer
      app
      padless
      fixed
      inset
      height="23"
      class="d-flex justify-space-between blueMain pt-0"
    >
      <v-card
        elevation="22"
        v-for="el in btn_footer"
        class="rounded-lg mt-n12"
        :key="el.name"
        :color="section != el.section ? el.color_btn : 'orangeDark'"
        width="33%"
        :height="section != el.section ? 50 : 50"
        @click.stop="el.disabled ? '' : (section = el.section)"
      >
        <div class="text_footer white--text">
          {{ el.name }}
        </div>
      </v-card>
      <div class="text-9 pa-0 mr-5 ml-auto mt-n1 white--text">
        v.testing
      </div>
      <!-- <rashodnik /> -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import Login from "./components/Login";
import rashodnik from "./components/Rashodnik";
import orders from "./components/Orders";
import document from "./components/Document";
import Scanner from "./components/Scanner";
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "App",
  components: {
    document,
    rashodnik,
    Scanner,
    Login,
    orders
  },
  data: () => ({
    key: Math.random(),
    barcode: "",
    datamatrix: "",
    ready_barcode: "",
    loading: false,
    username: undefined,
    btn_footer: [
      {
        name: "--- ---",
        color_btn: "grey4",
        section: "document",
        disabled: true
      },
      {
        name: "Ордеры",
        color_btn: "grey4",
        section: "orders",
        disabled: false
      },
      {
        name: "Сканер",
        color_btn: "grey4",
        section: "Scanner",
        disabled: false
      }
    ],
    section: "Scanner"
  }),
  computed: {
    ...mapState([
      "back_domain",
      "login_show",
      "article_searcher_enabled",
      "camera_enabled",
      "camera_accessible",
      "active_document",
      "vuex_sale_orders"
    ])
  },

  methods: {
    ...mapMutations([
      "login_on",
      "login_off",
      "camera_on",
      "camera_off",
      "article_searcher_on",
      "article_searcher_off",
      "update_vuex_stock",
      "update_vuex_placement"
    ]),
    article_searcher() {
      if (this.article_searcher_enabled) {
        this.article_searcher_off();
      } else {
        this.article_searcher_on();
        if (this.camera_enabled) this.camera_off();
      }
    },
    camera() {
      if (this.camera_enabled) {
        this.camera_off();
      } else {
        this.camera_on();
        if (this.article_searcher_enabled) this.article_searcher_off();
      }
    },
    username_from_token() {
      this.username = localStorage.getItem("access_token")
        ? VueJwtDecode.decode(localStorage.getItem("access_token")).user_claims
            ?.name
        : undefined;
    },

    close_login_dialog() {
      this.login_off();
    },

    change_loading(value) {
      this.loading = value;
    },

    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      this.login_on();
    },
    async get_stock() {
      return axios
        .post(this.back_domain + "service/stock", {})
        .then(res => res.data)
        .then(json => {
          if (json.response) this.update_vuex_stock(json.response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    async get_placement() {
      return axios
        .post(this.back_domain + "service/placement", {})
        .then(res => res.data)
        .then(json => {
          if (json.response) this.update_vuex_placement(json.response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  watch: {
    login_show() {
      this.username_from_token();
      this.key = Math.random();
    },
    active_document() {
      if (this.active_document) {
        let section = this.btn_footer.filter(el => el.section == "document");
        if (section.length) {
          section = section[0];
          this.section = section.section;
          section.disabled = false;
          // console.log(this.vuex_sale_orders);
          // console.log(this.active_document.Ref_Key);
          section.name = this.vuex_sale_orders[
            Object.keys(this.active_document)[0]
          ].Number;
        }
      }
    }
  },

  created: function() {
    this.username_from_token();
    this.get_stock();
    this.get_placement();
  },

  beforeDestroy: function() {}
};
</script>

<style lang="scss">
@import "./styles/variables.scss";

.text_footer {
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
}
.active_button {
  /* border-left: thick double grey; */
}
</style>
