<template>
  <div class="mt-3">
    <v-img
      v-show="loading"
      class="ma-4 grey1--text rounded-xl"
      src="processing.gif"
    >
    </v-img>
    <div v-for="s in stock" :key="s" class="mt-8">
      <Stock :document="document_in_stock(s)" />
    </div>
    <div v-for="a_n in auto_number" :key="a_n" class="mt-8 pr-6">
      <Automobile :document="document_in_auto(a_n)" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Automobile from "./DocumentAutomobileComponent";
import Stock from "./DocumentStockComponent";
export default {
  name: "Rashodnik",
  components: { Automobile, Stock },
  data: () => ({
    document: [],
    loading: false
  }),
  computed: {
    ...mapState(["back_domain"]),
    auto_number() {
      return [
        ...new Set(
          this.document.filter(el => el.auto_number).map(el => el.auto_number)
        )
      ];
    },
    stock() {
      return [
        ...new Set(this.document.filter(el => el.stock).map(el => el.stock))
      ];
    }
  },
  methods: {
    document_in_auto(a_n) {
      let doc = this.document.filter(element => element.auto_number == a_n);
      // console.log(doc);
      return doc;
    },
    document_in_stock(stock) {
      let doc = this.document.filter(
        element => element.stock == stock && !element.auto_number
      );
      // console.log(doc);
      return doc;
    },
    async get_orders() {
      // тащим данные из 1С
      // http://0.0.0.0:9061/
      if (this.loading) return;
      this.loading = true;
      return axios
        .post(this.back_domain + "service/orders", {})
        .then(res => res.data)
        .then(json => {
          this.document = json.response;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  created: function() {
    console.log(this.back_domain);

    this.get_orders();
  }
};
</script>
