<template>
  <div>
    <ProductCard v-if="product.guid" :product="product" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ProductCard from "./ProductCardComponent";
export default {
  name: "BarcodeProduct",
  components: {
    ProductCard
  },
  props: {
    barcode_data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    product: {}
  }),

  computed: {
    ...mapState(["back_domain"])
  },
  methods: {
    async get_barcode_data() {
      // тащим данные из 1С по баркоду
      // http://0.0.0.0:9061/
      if (this.loading) return;
      this.loading = true;
      return axios
        .post(this.back_domain + "service/barcode", {
          barcode: this.barcode_data.barcode
        })
        .then(res => res.data)
        .then(json => {
          if (!json.response.guid) {
            this.$emit("sendMessage", "barcode_not_found");
            this.loading = false;
          } else {
            this.product = json.response;
            this.loading = false;
            this.$emit("returnProduct", this.product);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
          this.$emit("sendMessage", "server_error");
        });
    }
  },
  created: function() {
    console.log("BarcodeProduct created", this.barcode_data.barcode);
    this.get_barcode_data();
    // компонент готов к уничтожению. Всегда. Бу га га га га
    this.$emit("finishd");
  }
};
</script>

<style scoped>
.position {
  position: absolute;
  top: 40px;
}
</style>
