import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import './registerServiceWorker'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

Vue.config.productionTip = false

// INTERCEPTORS
axios.interceptors.request.use((request) => {
      // if (!localStorage.getItem("refresh_token")
      //     && !localStorage.getItem("access_token")) Promise.reject();
      if (!request.headers['Authorization'])
      request.headers['Authorization'] =
          `Bearer ${localStorage.getItem("access_token")}`;
      if (typeof(request.data) == 'string'){
          request.data = JSON.parse(request.data);
      }
      return request;
});

const refreshAuthLogic = failedRequest => 
    axios.post('https://auth.oboi.ru/token/refresh', {}, {headers: {
          "Authorization": 'Bearer ' + localStorage.getItem("refresh_token"),
          "Access_token": localStorage.getItem("access_token") ?
                          localStorage.getItem("access_token") : ''
      }}).then(tokenRefreshResponse => {
            localStorage.setItem("access_token",
                                 tokenRefreshResponse.data.access_token);
            failedRequest.response.config.headers['Authorization'] =
                'Bearer ' + tokenRefreshResponse.data.access_token;
            return Promise.resolve();
      }).catch((err) => {
      if (err.response.status == 422) {
          store.commit('login_on');
      }
      return Promise.reject(err);
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic,
                             {statusCodes: [401]});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
