import Vue from "vue";
import VueRouter from "vue-router";
// import Scanner from "../components/Scanner.vue";
// import Login from "../components/Login.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Scanner",
  //   component: Scanner
  // },
  // {
  //   path: "login",
  //   name: "Login",
  //   component: Login
  // }
];

const router = new VueRouter({
  routes
});

export default router;
